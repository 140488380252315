import {
    Create,
    Edit, required,
    SimpleForm,
    TextInput, NumberInput, ReferenceInput, SelectInput
} from 'react-admin';
import {DateTimeInput} from "../components/date-time-pickers";

const editionForm = edit => (
    <SimpleForm>
        {edit &&
            <TextInput fullWidth disabled source="id" />
        }
        <ReferenceInput fullWidth perPage={1000} source="course_iter_id" reference="iterations">
            <SelectInput optionText={record => `${record.course.name} ${record.iteration_name}`} validate={required()} />
        </ReferenceInput>
        <NumberInput fullWidth source="session_number" validate={required()}/>
        <DateTimeInput fullWidth source="session_start" validate={required()}/>
        <DateTimeInput fullWidth source="session_end" validate={required()}/>
    </SimpleForm>
);

const SessionTitle = ({ record }) => {
    return <span>A editar {record && record.course_iteration ? `"${record.course_iteration.course.name} [${record.course_iteration.iteration_name}] Sessão#${record.session_number}"` : ''}</span>;
};

export const SessionEdit = props => (
    <Edit {...props} title={<SessionTitle/>}>
        {editionForm(true)}
    </Edit>
);

export const SessionCreate = props => (
    <Create {...props}>
        {editionForm(false)}
    </Create>
);