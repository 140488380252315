import { useState } from 'react';
import { useUpdate } from 'react-admin';
import { Switch } from '@material-ui/core';
import PropTypes from 'prop-types';

const RejectInput = ({ record, onFinish = () => {} }) => {
    const [loading, setLoading] = useState(false);
    const [update] = useUpdate();

    const handleChange = async (event) => {
        setLoading(true);
        try {
            await update('application_submissions',
                record.id,
                { rejected: event.target.checked },
                record
            );
            onFinish(true);
        } catch (error) {
            console.error(error);
            onFinish(false);
        } finally {
            setLoading(false);
        }
    };

    const disabled = record?.assigned_iterations.length > 0 || record?.rejection_email_sent;

    return (
        <Switch
            checked={record?.rejected}
            onChange={handleChange}
            disabled={loading || disabled}
        />
    );
};

export default RejectInput;

RejectInput.propTypes = {
    record: PropTypes.object,
    onFinish: PropTypes.func,
};
