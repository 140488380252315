import {
    BooleanField,
    Datagrid,
    FunctionField,
    NullableBooleanInput,
    ReferenceField,
    Show,
    SimpleShowLayout,
    TextField,
    useRecordContext,
    useResourceContext
} from 'react-admin';
import * as React from "react";
import ReferenceList from "../components/ReferenceList";
import {participantsFilters} from "../participants/list";
import {TextArrayField} from "../components/TextArrayField";
import BulkSetPresentButton from "./BulkSetPresentButton";
import BulkSetAbsentButton from "./BulkSetAbsentButton";
import SetPresentButton from "./SetPresentButton";
import SetAbsentButton from "./SetAbsentButton";
import LcDateField from "../components/LcDateField";

const SessionTitle = ({ record }) => {
    return <span>{record && record.course_iteration ? `${record.course_iteration.course.name} [${record.course_iteration.iteration_name}] Sessão#${record.session_number}` : ''}</span>;
};

const Aside = () => {
    const record = useRecordContext();
    const resource = useResourceContext();

    return record ? (
        <SimpleShowLayout record={record} resource={resource}>
            <TextField source="id" />
            <ReferenceField source="course_iter_id" reference="iterations" link="show">
                <FunctionField render={record => `${record.course.name} ${record.iteration_name}`} />
            </ReferenceField>
            <TextField source="session_number" />
            <LcDateField showTime={true} source="session_start" />
            <LcDateField showTime={true} source="session_end" />
        </SimpleShowLayout>
    ) : null;
};

const sessionParticipantFilters = [...participantsFilters.filter(f => !["course_iter_id", "in_discord"].includes(f.props.source)),
    <NullableBooleanInput source="present" alwaysOn/>,
];

export const SessionAttendancesBulkActionButtons = (props) => (
    <React.Fragment>
        <BulkSetPresentButton {...props} />
        <BulkSetAbsentButton {...props} />
    </React.Fragment>
);

const ResourceShow = props => (
    <Show
        aside={<Aside />}
        {...props}
        component="div"
        title={<SessionTitle/>}
    >
        <ReferenceList
            reference="session_participants"
            target="session_id"
            sort={{field: 'name', order: 'asc'}}
            filters={sessionParticipantFilters}
            filterDefaultValues={{ alunos: true }}
            bulkActionButtons={<SessionAttendancesBulkActionButtons />}
        >
            <Datagrid >
                <TextField source="name"/>
                <TextArrayField source="roles" />
                <FunctionField sortable={false} source="present" render={(record,source) =>
                    <BooleanField record={{...record, present:!!record.attended_session_id}} source={source}/>}
                />
                <SetPresentButton />
                <SetAbsentButton />
            </Datagrid>
        </ReferenceList>
    </Show>
);

export default ResourceShow;