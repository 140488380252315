import * as React from 'react';
import {Avatar} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles({
    avatar: {
        width: 60,
        height: 60,
        backgroundColor: 'aliceblue',
    },
    img: {
        objectFit: 'cover',
    },
    small: {
        width: 20,
        height: 20,
    },
    large: {
        width: 40,
        height: 40,
    },
});

export const CourseAvatar = ({
                                  record,
                                  size = 'large',
                              }) => {
    const classes = useStyles();
    if (!record) return null;
    return (
        <Avatar
            src={process.env.REACT_APP_BUCKET_BASE_URL + record.image_id}
            alt={record.name + " " + record.iteration_name}
            className={classes.avatar}
            imgProps={{ className: clsx(classes.img, classes[size]) }}
        />
    );
};