import * as React from 'react';
import { useState, useCallback, useMemo } from 'react';
import { Button, Chip } from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircleOutline';
import PropTypes from 'prop-types';
import { SanitizedGrid } from '../components/SanitizedElements';
import { getIterationName } from '../iterations/utils';
import IterationSelectionInput from './IterationSelectionInput';
import { useRefresh } from 'react-admin'

const AssignedIterationsField = ({record, showAssign=true}) => {
    const [isSelecting, setIsSelecting] = useState(false);
    const refresh = useRefresh();

    const handleFinish = useCallback((commited) => {
        setIsSelecting(false);
        if(commited) {
            refresh();
        }
    }, [refresh]);

    const applicationSubmissions = useMemo(() => [record], [record]);

    return isSelecting ? (
        <IterationSelectionInput applicationSubmissions={applicationSubmissions} onFinish={handleFinish} />
    ) : (
        <SanitizedGrid container spacing={1} alignItems="center">
            {showAssign && (<SanitizedGrid item>
            <Button disabled={record?.rejected} onClick={() => setIsSelecting(true)}>
                <AddIcon color={record?.rejected ? "disabled" : "primary"} />
            </Button>
            </SanitizedGrid>)}
            {record?.assigned_iterations.map(iteration => (
                <SanitizedGrid item key={iteration.id}>
                    <Chip key={iteration.id} label={getIterationName(iteration, true, false)} />
                </SanitizedGrid>
            ))}  
        </SanitizedGrid>
    );
}

AssignedIterationsField.propTypes = {
    record: PropTypes.object,
}

export default AssignedIterationsField;
