import {
    EmailField,
    ReferenceField,
    Show,
    Tab,
    TabbedShowLayout,
    TextField,
} from 'react-admin';
import * as React from "react";

import {TextArrayField} from "../components/TextArrayField";
import {ApplicationQuestions} from "../applications/ApplicationQuestions";
import {UserTimelineField} from "../users/UserTimelineField";
import LcDateField from "../components/LcDateField";
import {getApplicationSpecName} from "../application_specs/utils";

const ApplicationTitle = ({ record }) => (
    <span>Submissão {record ? `"${record.name} [${getApplicationSpecName(record.application_spec)}]"` : ''}</span>
);

const ResourceShow = props => (
    <Show
        {...props}
        component="div"
        title={<ApplicationTitle/>}
    >
        <TabbedShowLayout syncWithLocation={!props.in_list}>
            <Tab label="Contactos">
                <LcDateField source="created_at" showTime={true}/>
                <TextField fullWidth source="name" />
                <ReferenceField link={false} fullWidth label="Email" source="user_id" reference="users">
                    <EmailField fullWidth source="email" />
                </ReferenceField>
                <TextField fullWidth source="phone" />
                <TextField source="parent_name" />
                <EmailField source="parent_email" />
                <TextField source="parent_phone" />
            </Tab>
            <Tab label="Dados Pessoais">
                <LcDateField source="birthdate" />
                <TextField source="gender" />
                <TextField source="nationality" />
                <TextField source="school_year" />
                <TextField source="school_name" />
                <TextField source="school_group" />
                <TextField source="school_location" />
                <TextField source="school_district" />
            </Tab>
            <Tab label="Histórico e Info">
                <TextArrayField source="history_t2" />
                <TextField source="history_t2_app" />
                <TextField source="history_oc" />
                <TextArrayField source="info_interests" />
                <TextField source="info_english" />
                <TextField source="info_computer" />
                <TextField source="info_internet"/>
            </Tab>
            <Tab label="Perguntas">
                <ApplicationQuestions source="questions" />
            </Tab>
            <Tab label="Histórico">
                <ReferenceField link={false} fullWidth label="Atividades TT2" source="user_id" reference="users">
                    <UserTimelineField fullWidth in_list={props.in_list}/>
                </ReferenceField>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ResourceShow;