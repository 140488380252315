import {
    BooleanField,
    BooleanInput,
    EmailField,
    FunctionField,
    ReferenceField,
    SaveButton,
    SaveContextProvider,
    SelectInput,
    Show,
    SimpleForm,
    Tab,
    TabbedShowLayout,
    TextField,
    TextInput,
    Toolbar,
    TopToolbar,
    useNotify,
    useRecordContext,
    useUpdate
} from 'react-admin';
import * as React from "react";
import {useCallback, useMemo} from "react";

import {TextArrayField} from "../components/TextArrayField";
import {ApplicationQuestions} from "./ApplicationQuestions";
import UserPageButton from "../components/UserPageButton";
import {UserTimelineField} from "../users/UserTimelineField";
import {HostedFileField} from "../components/HostedFile";
import LcDateField from "../components/LcDateField";
import SendLoginsButton from "./SendLoginsButton";

const ApplicationTitle = ({ record }) => (
    <span>Candidatura {record ? `"${record.name} [${record.course_iteration.course.name} ${record.course_iteration.iteration_name}]"` : ''}</span>
);

const ApplicationShowActions = ({ data }) => (
    <TopToolbar>
        {data &&
            <UserPageButton {...data}/>
        }
        <SendLoginsButton />
    </TopToolbar>
);

const EditAside = () => {
    const record = useRecordContext();
    const notify = useNotify();

    const [update] = useUpdate('applications');

    const handleSave = useCallback(
            (values) => {
                update(
                    'applications',
                    values.id,
                    values,
                    {},
                    {
                        onSuccess: () => {
                            notify(
                                'ra.notification.updated',
                                'info',
                                {
                                    smart_count: 1,
                                }
                            );
                        },
                        onFailure: error => {
                            notify(
                                typeof error === 'string'
                                    ? error
                                    : error.message ||
                                    'ra.notification.http_error',
                                'warning',
                                {
                                    _:
                                        typeof error === 'string'
                                            ? error
                                            : error && error.message
                                            ? error.message
                                            : undefined,
                                }
                            );
                        },
                    }
                )
        },
        [update, notify]);

    const saveContext = useMemo(() => ({
        save: handleSave
    }), [handleSave]);

    const ApplicationSideToolbar = props => (
        <Toolbar {...props}>
            <SaveButton />
        </Toolbar>
    );

    return record ? (
        <SaveContextProvider value={saveContext}>
            <SimpleForm resource="applications" record={record} save={handleSave} toolbar={<ApplicationSideToolbar />}>
                <BooleanInput source="accepted"/>
                <TextInput multiline source="comment"/>
                <SelectInput
                    source="recommendation"
                    defaultValue="neutro"
                    choices={[
                        {id: "não", name: "Não recomendado (-)"},
                        {id: "neutro", name: "Neutro (~)"},
                        {id: "sim", name: "Recomendado (+)"}
                    ]}
                />
            </SimpleForm>
        </SaveContextProvider>
    ) : null;
};


const ResourceShow = props => (
    <Show
        aside={<EditAside />}
        {...props}
        component="div"
        title={<ApplicationTitle/>}
        actions={props.in_list ? null : <ApplicationShowActions />}
    >
        <TabbedShowLayout syncWithLocation={!props.in_list}>
            <Tab label="Contactos">
                <LcDateField source="created_at" showTime={true}/>
                <ReferenceField source="course_iter_id" reference="iterations" link="show">
                    <FunctionField render={record => `${record.course.name} ${record.iteration_name}`} />
                </ReferenceField>
                <TextField fullWidth source="name" />
                <ReferenceField link={false} fullWidth label="Email" source="user_id" reference="users">
                    <EmailField fullWidth source="email" />
                </ReferenceField>
                <TextField fullWidth source="phone" />
                <TextField source="parent_name" />
                <EmailField source="parent_email" />
                <TextField source="parent_phone" />
            </Tab>
            <Tab label="Dados Pessoais">
                <LcDateField source="birthdate" />
                <TextField source="gender" />
                <TextField source="nationality" />
                <TextField source="school_year" />
                <TextField source="school_name" />
                <TextField source="school_group" />
                <TextField source="school_location" />
                <TextField source="school_district" />
            </Tab>
            <Tab label="Histórico e Info">
                <TextArrayField source="history_t2" />
                <TextField source="history_t2_app" />
                <TextField source="history_oc" />
                <TextArrayField source="info_interests" />
                <TextField source="info_english" />
                <TextField source="info_computer" />
                <TextField source="info_internet"/>
            </Tab>
            <Tab label="Perguntas">
                <ApplicationQuestions source="questions" />
            </Tab>
            <Tab label="Histórico">
                <ReferenceField link={false} fullWidth label="Atividades TT2" source="user_id" reference="users">
                    <UserTimelineField fullWidth in_list={props.in_list}/>
                </ReferenceField>
            </Tab>
            <Tab label="Confirmação">
                <BooleanField source="confirmed" />
                <HostedFileField source="id_card_image"/>
                <TextField source="donation_amount" />
                <HostedFileField source="donation_receipt" />
                <TextField source="vat_number" />
                <TextField source="confirmation_obs"/>
            </Tab>
        </TabbedShowLayout>
    </Show>
);

export default ResourceShow;