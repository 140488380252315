import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';

import {Button, useDataProvider, useNotify, useUnselectAll,} from 'react-admin';
import DraftsIcon from "@material-ui/icons/Drafts";

const noSelection = [];

const BulkSendCertificatesButton = ({
    selectedIds = noSelection,
}) => {
    const notify = useNotify();
    const unselectAll = useUnselectAll('applications');
    const [loading, setLoading] = useState(false);
    const dataProvider = useDataProvider();

    const submit = async () => {
        setLoading(true);
        dataProvider.create(`course_management/send_student_logins/`, {
            data: {
                raw_request: "POST",
                participants: selectedIds.map(id => {
                    const ids = id.split('/');
                    return {
                        course_iter_id: ids[0],
                        user_id: ids[1]
                    }
                })
            }
        }).then(() => {
            notify("resources.applications.send_student_logins_success");
            unselectAll();
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() =>
            setLoading(false)
        )
    }

    return (
        <Button
            label="resources.applications.send_student_logins_button"
            onClick={submit}
            disabled={loading}
        >
            <DraftsIcon />
        </Button>
    );
};

BulkSendCertificatesButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkSendCertificatesButton;
