import React, { useState, useEffect } from 'react';
import { useDataProvider, useTranslate } from 'react-admin';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Select, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

const ProgramSelectionDialog = ({ open, onClose, onSelect }) => {
    const [selectedProgram, setSelectedProgram] = useState('');
    const [programs, setPrograms] = useState([]);
    const dataProvider = useDataProvider();
    const translate = useTranslate();

    useEffect(() => {
        dataProvider.getList('programs', {
            pagination: { page: 1, perPage: 100 },
            sort: { field: 'name', order: 'ASC' },
            filter: {},
        }).then(({ data }) => {
            setPrograms(data);
        }).catch(error => {
            console.error(error);
        });
    }, [dataProvider]);

    const handleSelect = () => {
        onSelect(selectedProgram);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{translate('resources.iterations.program_selection_dialog_title')}</DialogTitle>
            <DialogContent>
                <Select
                    value={selectedProgram}
                    onChange={(e) => setSelectedProgram(e.target.value)}
                    fullWidth
                >
                    {programs.map(program => (
                        <MenuItem key={program.id} value={program.slug}>
                            {program.name}
                        </MenuItem>
                    ))}
                </Select>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    {translate('ra.action.cancel')}
                </Button>
                <Button onClick={handleSelect} color="primary" disabled={!selectedProgram}>
                    {translate('ra.action.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

ProgramSelectionDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default ProgramSelectionDialog;