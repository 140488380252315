import {
    BooleanField,
    Datagrid,
    FilterLiveSearch,
    FunctionField,
    List,
    NullableBooleanInput,
    ReferenceField,
    ReferenceInput,
    SelectInput,
    Show,
    SimpleShowLayout,
    TextField
} from 'react-admin';
import * as React from "react";
import PrefixedUrlField from "../components/PrefixedUrlField";
import AttendancesField from "../components/AttendancesField";
import {TextArrayField} from "../components/TextArrayField";
import BigPagination from "../components/BigPagination";
import BulkSendCertificatesButton from "./BulkSendCertificatesButton";

export const ParticipantsBulkActionButtons = (props) => (
    <React.Fragment>
        <BulkSendCertificatesButton {...props} />
    </React.Fragment>
);

export const participantsFilters = [
    <FilterLiveSearch source="q" alwaysOn/>,
    <NullableBooleanInput source="alunos" alwaysOn/>,
    <ReferenceInput perPage={1000} source="course_iter_id" reference="iterations" sort={{ field: 'start_date', order: 'DESC' }}>
        <SelectInput
            optionText={(choice) =>
                choice.id // the empty choice is { id: '' }
                    ? `${choice.course.name} ${choice.iteration_name}`
                    : ''
            }
        />
    </ReferenceInput>,
    <NullableBooleanInput source="in_discord"/>,
];

const ParticipantExpand = (props) => (
    <Show
        {...props}
        title=" "
    >
        <SimpleShowLayout>
            <PrefixedUrlField source="invite_code" prefix="https://discord.gg/"/>
            <TextField source="discord_user_id"/>
        </SimpleShowLayout>
    </Show>
)

export const ParticipantDatagrid = ({permissions, ...props}) => (
    <Datagrid rowClick="show" expand={<ParticipantExpand/>} {...props}>
        {!props.hide_course &&
            <ReferenceField source="course_iter_id" link="show" reference="iterations">
                <FunctionField render={record => `${record.course.name} ${record.iteration_name}`} />
            </ReferenceField>
        }
        <TextField source="name"/>
        {permissions && permissions.isAdmin() &&
            <ReferenceField source="user_id" reference="users" sortable={false} link="show">
                <TextField source="email" />
            </ReferenceField>
        }
        <AttendancesField source="attendances" sortable={false}/>
        <TextArrayField source="roles" />
        <FunctionField sortable={false} source="in_discord" render={(record,source) =>
            <BooleanField record={{...record, in_discord:!!record.discord_user_id}} source={source}/>}
        />
    </Datagrid>
);

const ResourceList = ({permissions, ...props}) => (
    <List {...props} sort={{ field: 'created_at', order: 'DESC' }}
          bulkActionButtons={false}
          filters={participantsFilters}
          filterDefaultValues={{ alunos: true }}
          pagination={<BigPagination />}
    >
        <ParticipantDatagrid permissions={permissions} />
    </List>
);



export default ResourceList;
