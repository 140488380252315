import {
    BooleanInput,
    Button,
    Edit,
    EmailField,
    FormTab,
    FunctionField,
    ReferenceField,
    SelectArrayInput,
    ShowButton,
    SimpleForm,
    SimpleShowLayout,
    TabbedForm,
    TextField,
    TextInput,
    TopToolbar,
    useRecordContext
} from 'react-admin';
import {HostedImageInput, PreviewImage} from "../components/HostedImageFieldInput";
import * as React from "react";
import {useCallback} from "react";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import {useForm} from 'react-final-form';
import {SanitizedGrid, SanitizedP} from "../components/SanitizedElements";
import ToolbarConditionalDelete from "../components/ToolbarConditionalDelete";

const StaffUserTitle = ({ record }) => (
    <span>A editar {record ? `"${record.name} [${record.course_iteration.course.name} ${record.course_iteration.iteration_name}]"` : ''}</span>
);

const StaffUserEditActions = ({ permissions, data }) => (
    <TopToolbar>
        {data && permissions && permissions.canViewResource("users") &&
            <ShowButton basePath="/users" label="Página do utilizador" record={{id: data.user_id}}/>
        }
    </TopToolbar>
);

const ImageToApprove = (props) => {
    const form = useForm();
    const record = useRecordContext(props);

    const handleClick = useCallback(() => {
        form.change('image_id', record.image_id_pending);
    }, [form, record]);

    return record && record.image_id_pending && record.image_id_pending !== record.image_id?
        <span>
            <SanitizedP style={{width: "100%"}}>
                Última imagem submetida pelo instrutor:
            </SanitizedP>
            <PreviewImage record={record} source="image_id_pending" />
            <Button
                label="Aprovar imagem"
                onClick={handleClick}
            >
                <DoneAllIcon />
            </Button>
        </span> : null;
}


export const StaffUserEdit = ({permissions, ...props}) => {
    return (<Edit {...props} title={<StaffUserTitle/>} actions={<StaffUserEditActions permissions={permissions}/>}>
        <TabbedForm
            toolbar={<ToolbarConditionalDelete condition={permissions && permissions.isAdmin()}/>}
        >
            <FormTab label="Contactos">
                {
                    permissions && permissions.canViewResource("iterations") &&
                    <ReferenceField source="course_iter_id" reference="iterations" link="show">
                        <FunctionField render={record => `${record.course.name} ${record.iteration_name}`}/>
                    </ReferenceField>
                }
                {
                    permissions && permissions.canViewResource("users") &&
                    <ReferenceField link={false} fullWidth source="user_id" reference="users">
                        <EmailField fullWidth source="email"/>
                    </ReferenceField>
                }
                <TextInput fullWidth source="name"/>
                {permissions && permissions.isAdmin() &&
                <TextInput fullWidth source="phone"/>
                }
                {permissions && permissions.isAdmin() &&
                <SelectArrayInput fullWidth source="roles" choices={[
                    {id: 'instrutor', name: 'Instrutor'},
                    {id: 'autor', name: 'Autor'},
                    {id: 'coordenador', name: 'Coordenador'},
                    {id: 'apoio', name: 'Apoio (para organizações)'}
                ]}/>
                }
            </FormTab>
            <FormTab label="Descrição">
                <SanitizedGrid container spacing={1} style={{width: "100%"}} >
                    <SanitizedGrid item xs={6}>
                        <TextInput multiline fullWidth source="description"/>
                        <BooleanInput source="validated"/>
                    </SanitizedGrid>
                    <SanitizedGrid item xs={6}>
                        <SimpleShowLayout>
                            <TextField fullWidth source="place_born"/>
                            <TextField fullWidth source="place_residence"/>
                            <TextField fullWidth source="academic_experience"/>
                            <TextField fullWidth source="other_interests"/>
                            <TextField fullWidth source="interesting_fact"/>
                        </SimpleShowLayout>
                    </SanitizedGrid>
                </SanitizedGrid>
            </FormTab>
            <FormTab label="Imagem">
                <ImageToApprove/>
                <HostedImageInput source="image_id"/>
            </FormTab>
        </TabbedForm>
    </Edit>)
};

export const ExpandableStaffUserEdit = ({permissions, ...props}) => {
    return (<Edit {...props} title={<StaffUserTitle/>} actions={<StaffUserEditActions permissions={permissions}/>}>
        <SimpleForm>
            <SanitizedGrid container spacing={1} style={{width: "100%"}}>
                <SanitizedGrid item xs={6}>
                    <TextInput multiline fullWidth source="description"/>
                    {permissions && permissions.isAdmin() &&
                    <SelectArrayInput fullWidth source="roles" choices={[
                        {id: 'instrutor', name: 'Instrutor'},
                        {id: 'autor', name: 'Autor'},
                        {id: 'coordenador', name: 'Coordenador'},
                        {id: 'apoio', name: 'Apoio (para organizações)'}
                    ]}/>
                    }
                    <BooleanInput source="validated"/>
                </SanitizedGrid>
                <SanitizedGrid item xs={6}>
                    <SimpleShowLayout>
                        <TextField fullWidth source="place_born"/>
                        <TextField fullWidth source="place_residence"/>
                        <TextField fullWidth source="academic_experience"/>
                        <TextField fullWidth source="other_interests"/>
                        <TextField fullWidth source="interesting_fact"/>
                    </SimpleShowLayout>
                </SanitizedGrid>
            </SanitizedGrid>
            <ImageToApprove/>
            <HostedImageInput source="image_id"/>
        </SimpleForm>
    </Edit>);
};