import {
    Create,
    Edit,
    FormTab,
    ReferenceInput,
    SelectInput,
    TabbedForm,
    TextInput,
    required,
} from 'react-admin'

import {getApplicationSpecName} from "./utils";
import {JSONInput} from "../components/JsonFieldInput";
import {DateTimeInput} from '../components/date-time-pickers/';
import ToolbarWithoutDelete from "../components/ToolbarWithoutDelete";

const SelectProgramNoASO = ({choices, ...rest}) => {
    const asoChoice = choices?.find(choice => choice.slug === 'aso');
    if (asoChoice) {
        asoChoice.disabled = true;
    }
    return <SelectInput choices={choices} {...rest} />
}

const ApplicationSpecTitle = ({ record }) => {
    return <span>A editar candidatura "{getApplicationSpecName(record)}"</span>;
};

const questionsSchema = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            title: {
                type: 'string'
            },
            description: {
                type: 'string'
            },
            fields: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'string'
                        },
                        label: {
                            type: 'string'
                        },
                        description: {
                            type: 'string'
                        },
                        type: {
                            type: 'string',
                            "enum" : ["text", "number", "email", "phone", "area", "date", "select",
                                "select_other", "select_other_number", "tags", "check", "radio",
                                "file", "image"]
                        },
                        multiple: {
                            type: 'boolean'
                        },
                        required: {
                            type: 'boolean'
                        },
                        max: {
                            type: 'number'
                        },
                        options: {
                            type: 'array',
                            items: {
                                type: 'string'
                            }
                        }
                    },
                    additionalProperties: false,
                    required: ["id", "label", "type"],
                }
            }
        },
        required: ["title", "fields"],
        additionalProperties: false,
    },
};

const editionForm = edit => (
    <TabbedForm toolbar={<ToolbarWithoutDelete />}>
        <FormTab label="resources.application_specs.edition_tab_general">
            {edit &&
                <TextInput fullWidth disabled source="id" />
            }
            <ReferenceInput fullWidth source="program_id" reference="programs" sort={{ field: 'name', order: 'ASC' }}>
                <SelectProgramNoASO validate={required()} />
            </ReferenceInput>
            <TextInput fullWidth source="edition" validate={required()} />
            <DateTimeInput fullWidth source="start_date" />
            <DateTimeInput fullWidth source="end_date" />
        </FormTab>
        <FormTab label="resources.application_specs.edition_tab_format">
            <JSONInput source="questions" schema={questionsSchema}/>
        </FormTab>
    </TabbedForm>
);

export const ApplicationSpecEdit = props => (
    <Edit {...props} title={<ApplicationSpecTitle />}>
        {editionForm(true)}
    </Edit>
);

export const ApplicationSpecCreate = props => (
    <Create {...props}>
        {editionForm(false)}
    </Create>
);
