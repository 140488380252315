import * as React from 'react';
import {SimpleList, useGetList} from 'react-admin';
import {formatDistance} from 'date-fns';

import {Avatar as MuiAvatar} from '@material-ui/core';

import {pt} from 'date-fns/esm/locale'

import applications from '../applications';
import CardWithIcon from "./CardWithIcon";

export const LastApplications = () => {
    const {
        data,
        ids,
        total,
        loaded,
    } = useGetList(
        'applications',
        { page: 1, perPage: 10 },
        { field: 'created_at', order: 'DESC' },
        { },
    );
    return (
        <>
            <CardWithIcon
                to="/applications"
                icon={applications.icon}
                title="Últimas candidaturas"
            >
                <SimpleList
                    basePath="/applications"
                    linkType="show"
                    ids={ids}
                    data={data}
                    total={total}
                    loaded={loaded}
                    secondaryText={record => record.name}
                    tertiaryText={record =>
                        formatDistance(
                            new Date(record.created_at),
                            new Date(),
                            {
                                addSuffix: true,
                                locale: pt
                            }
                        )
                    }
                    primaryText={record => `${record.course_iteration.course.name} ${record.course_iteration.iteration_name}`}
                    leftAvatar={
                        record => {
                            const names = record.name.trim().split(/[ ]+/);
                            return <MuiAvatar>
                                {names[0].charAt(0)}
                                {names[names.length - 1].charAt(0)}
                            </MuiAvatar>;
                        }
                    }
                />
            </CardWithIcon>
        </>
    );
};
