import {Button, useNotify, useRecordContext, useRefresh} from "react-admin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import TextField from "@material-ui/core/TextField";
import MUIButton from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import { makeStyles } from '@material-ui/core/styles';
import { useDataProvider } from 'react-admin';
import DateRangeIcon from '@material-ui/icons/DateRange';
import * as React from "react";
import MUIDateTimePicker from "../../components/date-time-pickers/MUIDateTimePicker";

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    formControl: {
        margin: theme.spacing(3),
    },
}));

const BulkCreateSessionsButton = () => {
    const classes = useStyles();
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const record = useRecordContext();

    const [open, setOpen] = React.useState(false);

    const [nrSessions, setNrSessions] = React.useState(7);
    const [firstSessionStart, setFirstSessionStart] = React.useState();
    const [firstSessionEnd, setFirstSessionEnd] = React.useState();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
       dataProvider.create(`sessions/bulk-create`, {
            data: {
                raw_request: "POST",
                iteration_id: record.id,
                number_sessions: nrSessions,
                first_session_start: firstSessionStart,
                first_session_end: firstSessionEnd,
            }
        }).then(() => {
            notify("resources.sessions.bulk_added");
            refresh();
        }).catch(error =>
            notify(error.message, 'error')
        ).finally(() => {
            setOpen(false);
        });
    };

    return (
        <div className={classes.root}>
            <Button
                label="resources.iterations.add_weekly_sessions_button"
                onClick={handleClickOpen}
            >
                <DateRangeIcon />
            </Button>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Adicionar à Equipa</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Esta ação adiciona em bulk N sessões para um curso, de 7 em 7 dias, a partir da sessão inicial.
                    </DialogContentText>

                    <TextField type="number" label="Número de sessões" value={nrSessions} onChange={event => setNrSessions(event.target.value)}/>
                    <br />
                    <MUIDateTimePicker
                        label="Início da primeira sessão"
                        value={firstSessionStart} onChange={ setFirstSessionStart }
                    />
                    <br />
                    <MUIDateTimePicker
                        label="Fim da primeira sessão"
                        value={firstSessionEnd} onChange={ setFirstSessionEnd}
                    />
                </DialogContent>
                <DialogActions>
                    <MUIButton onClick={handleClose} color="primary">
                        Cancelar
                    </MUIButton>
                    <MUIButton onClick={handleSubmit} color="primary">
                        Submeter
                    </MUIButton>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default BulkCreateSessionsButton;