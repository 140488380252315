import {
    BooleanField,
    BulkDeleteButton,
    Datagrid,
    FunctionField,
    List,
    NullableBooleanInput,
    ReferenceField,
    TextField
} from 'react-admin';
import {TextArrayField} from "../components/TextArrayField";
import * as React from "react";
import {ExpandableStaffUserEdit} from "./edition";
import BigPagination from "../components/BigPagination";
import IterationFilter from "../components/IterationFilter";
import LcDateField from "../components/LcDateField";

const DefaultBulkActionButtons = props => <BulkDeleteButton {...props} />;

const ResourceList = ({permissions, ...props}) => {
    let filters = [<NullableBooleanInput source="validated" label="Validado" alwaysOn/>];

    if (permissions && permissions.canViewResource("iterations"))
        filters.push(<IterationFilter source="course_iter_id"/>)

    return <List {...props} sort={{field: 'created_at', order: 'DESC'}}
          filters={filters}
          filterDefaultValues={{'validated': false}}
          pagination={<BigPagination/>}
          bulkActionButtons={permissions && permissions.isAdmin() ? <DefaultBulkActionButtons /> : false}
    >
        <Datagrid rowClick="edit" expand={<ExpandableStaffUserEdit permissions={permissions}/>}>
            {
                permissions && permissions.canViewResource("iterations") &&
                <ReferenceField source="course_iter_id" reference="iterations" link="show">
                    <FunctionField render={record => `${record.course.name} ${record.iteration_name}`}/>
                </ReferenceField>
            }
            <TextField source="name"/>
            {
                permissions && permissions.canViewResource("users") &&
                <ReferenceField source="user_id" reference="users" sortable={false} link="show">
                    <TextField source="email"/>
                </ReferenceField>
            }
            <LcDateField source="updated_at" showTime={true} />
            <LcDateField source="created_at" showTime={true} />
            <TextArrayField source="roles"/>
            <BooleanField source="validated" label="Validado"/>
        </Datagrid>
    </List>
};



export default ResourceList;