import {
    CloneButton, 
    Datagrid, 
    EditButton, 
    FunctionField, 
    List, 
    SimpleList, 
    TextField, 
} from 'react-admin';
import {useMediaQuery} from '@material-ui/core';
import * as React from "react";
import rowStyle from "./rowStyle";
import {makeStyles, useTheme} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import BigPagination from "../components/BigPagination";
import LcDateField from "../components/LcDateField";
import {CourseAvatar} from "./CourseAvatar";
import {getIterationName} from './utils';

const useListStyles = makeStyles({
    headerRow: {
        borderLeftColor: 'transparent',
        borderLeftWidth: 5,
        borderLeftStyle: 'solid',
    },
    headerCell: {
        padding: '6px 8px 6px 8px',
    },
    rowCell: {
        padding: '6px 8px 6px 8px',
    },
});

export const IterationDatagrid = ({permissions, selectedRow, in_courses, ...props}) => {
    const classes = useListStyles();
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const theme = useTheme();
    const rowS = rowStyle(selectedRow, theme);

    return isSmall ? (
            <SimpleList
                rowStyle={rowS}
                primaryText={record => <Typography variant="body2">{getIterationName(record, !in_courses, !in_courses)}</Typography>}
                secondaryText={record => permissions && permissions.isAdmin() ? <>{record.old_id} <strong>{record.applications_nr}</strong> candidaturas</> : null}
                tertiaryText={record =>
                    <>
                        <LcDateField source="start_date" record={record}/>
                        -
                        <LcDateField source="end_date" record={record}/>
                    </>}
                linkType="show"
                leftAvatar={record => <CourseAvatar record={record}/>}
            />
        ) : (
            <Datagrid rowClick="show"
                rowStyle={rowS}
                classes={{
                  headerRow: classes.headerRow,
                  headerCell: classes.headerCell,
                  rowCell: classes.rowCell,
                }}
                {...props}
            >
            <FunctionField
                label={in_courses ? "resources.iterations.fields.iteration_name" : "Nome"} 
                render={record => getIterationName(record, !in_courses, false)}/>
            {!in_courses && <TextField source="course.program.slug" sortBy="course/program/slug"/>}
            <TextField source="old_id"/>
            <LcDateField source="start_date" />
            <LcDateField source="end_date" />
            {permissions && permissions.isAdmin() &&
                <TextField source="students_nr"/>
            }
            {permissions && permissions.isAdmin() &&
                <TextField source="applications_nr"/>
            }
            {permissions && permissions.isAdmin() &&
                <EditButton/>
            }
            {permissions && permissions.isAdmin() &&
                <CloneButton />
            }

        </Datagrid>
    );
};

const ResourceList = ({permissions, ...props}) => (
    <List {...props} sort={{ field: 'start_date', order: 'DESC' }} bulkActionButtons={false} pagination={<BigPagination />}>
        <IterationDatagrid permissions={permissions} />
    </List>
);


export default ResourceList;