import {Labeled, Loading, useGetOne} from "react-admin";
import * as React from "react";
import Typography from "@material-ui/core/Typography";
import {Chip} from "@material-ui/core";

export const ApplicationQuestions = ({ record, source }) => {
    
    const is_aso = record?.course_iteration?.course.program.slug === 'aso';

    const { data, loading, error } = useGetOne(
        is_aso ? 'iterations' : 'application_specs',
        is_aso ? record['course_iter_id'] : record['application_spec_id']
    );

    if (loading) { return <Loading />; }

    const questions = is_aso ? data?.application_questions : data?.questions;
    if (error || !questions) { return <p>ERROR</p>; }

    const answers = record[source];

    return questions.map(section => (
            <div key={section["title"]}>
                <Typography variant="h6" gutterBottom>
                    {section["title"]}
                </Typography>
                {
                    section["fields"].map(field => {

                        if (field["type"] === "tags")
                            return (
                                <Labeled key={field["id"]} label={field["label"]}>
                                    {answers[field["id"]].map(tag =>
                                        <Chip key={tag}>{tag}</Chip>
                                    )}
                                </Labeled>
                            )
                        else
                            return (
                                <Labeled key={field["id"]} label={field["label"]}>
                                    <span>
                                        {answers[field["id"]]}
                                    </span>
                                </Labeled>
                            )
                    })
                }
            </div>
        ));
};
