import React, {useEffect, useState} from 'react';
import {
    Button,
    useDataProvider, useNotify
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import MUIButton from '@material-ui/core/Button';
import {useForm} from "react-final-form";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(1),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));


const DiscordCreateButton = ({ onChange, record }) => {
    const [showDialog, setShowDialog] = useState(false);
    const classes = useStyles();
    const [activeStep, setActiveStep] = useState(0);
    const [linkAddBot, setLinkAddBot] = useState(false);
    const [loading, setLoading] = useState(false);
    const form = useForm();
    const steps = getSteps();
    const notify = useNotify();
    const dataProvider = useDataProvider();

    const serverName = `After School - ${record.course.name} ${record.iteration_name}`;

    const copyName = () => {
        navigator.clipboard.writeText(serverName)
        notify("resources.discords.copied");
    };

    const handleClick = () => {
        setShowDialog(true);
    };

    const handleCloseClick = () => {
        setShowDialog(false);
    };

    const handleNext = () => {
        if (activeStep === steps.length) {
            setLoading(true);
            dataProvider.getList('discords', {pagination: {perPage: 1000, page: 1}, filter: {}, sort: {field: "id", order: "asc"}})
                .then(({ data }) => {
                    const newServer = data.filter(item => item.name === serverName);
                    if (newServer && newServer.length > 0){
                        onChange();
                        form.change('discord_server_id', newServer[0].id);
                        notify("resources.discords.new_found")
                    } else
                        notify("resources.discords.not_found", 'warning')
                })
                .catch(error => {
                    notify(error.message, 'error');
                }).finally(() => {
                    setLoading(false);
                    setShowDialog(false);
                    handleReset();
                });
        } else
            setActiveStep((prevActiveStep) => prevActiveStep + 1);

    };

    const handleBack = () => {
        if (activeStep === 0)
            setShowDialog(false);
        else
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    function getSteps() {
        return ['Nome', 'Criar com template', 'Adicionar o bot', 'Mover a role'];
    }

    useEffect(() => {
        dataProvider.create(`discords/join`, {
            data: {
                raw_request: "GET"
            }
        }).then(result => {
            if (result)
                setLinkAddBot(result["data"]["json"]);
        })
    }, [setLinkAddBot, dataProvider]);


    function getStepContent(step) {
        switch (step) {
            case 0:
                return (
                    <>
                        <Typography>
                            O servidor discord deverá ter o seguinte nome:
                        </Typography>
                        <Typography variant="h6" component="h2" color="textSecondary">{serverName}</Typography>
                        <MUIButton
                            variant="contained"
                            color="secondary"
                            onClick={copyName}
                            className={classes.button}
                        >
                            Copiar nome
                        </MUIButton>
                    </>
                );
            case 1:
                return (
                    <>
                        <Typography>
                            Criar o servidor a partir do template:
                        </Typography>
                        <Link href={process.env.REACT_APP_DISCORD_TEMPLATE} target="_blank" rel="noopener noreferrer">
                            <MUIButton
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                            >
                                Template
                            </MUIButton>
                        </Link>
                    </>
                );
            case 2:
                return (
                    <>
                        <Typography>
                            Clicar no link abaixo, selecionar o servidor criado ("{serverName}") e confirmar as permissões do bot.
                        </Typography>
                        <Link href={linkAddBot} target="_blank" rel="noopener noreferrer">
                            <MUIButton
                                disabled={!linkAddBot}
                                variant="contained"
                                color="secondary"
                                className={classes.button}
                            >
                                Adicionar o bot
                            </MUIButton>
                        </Link>
                    </>
                );
            case 3:
                return (
                    <>
                        <Typography>
                            No servidor, ir a "Server settings" > "Roles" e arrastar a role do bot ("AfterSchool") para o topo da lista. A role deverá ser a primeira para que o bot possa gerir as roles dos utilizadores.
                        </Typography>
                    </>
                );
            default:
                return '?';
        }
    }

    return (
        <>
            <Button onClick={handleClick} label="ra.action.create">
                <IconContentAdd />
            </Button>
            <Dialog
                fullWidth
                open={showDialog}
                onClose={handleCloseClick}
                aria-label="Criar um servidor"
            >
                <DialogTitle>Criar o servidor discord para este curso</DialogTitle>
                <DialogContent>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepLabel>{label}</StepLabel>
                                <StepContent>
                                    {getStepContent(index)}
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </DialogContent>
                <DialogActions className={classes.actionsContainer}>
                    <MUIButton
                        disabled={loading}
                        onClick={handleBack}
                        className={classes.button}
                    >
                        {activeStep === 0 ? 'Cancelar' : 'Anterior'}
                    </MUIButton>
                    <MUIButton
                        disabled={loading}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                    >
                        {activeStep === steps.length ? 'Terminar' : 'Seguinte'}
                    </MUIButton>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default DiscordCreateButton;
