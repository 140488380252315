import {
    ArrayInput,
    BooleanInput,
    Create,
    Edit,
    FormDataConsumer,
    FormTab,
    ReferenceInput,
    SelectInput,
    SimpleFormIterator,
    TabbedForm,
    TextInput,
    required,
    regex,
    number,
    minValue,
    useRedirect,
    useEditController,
    useCreateController
} from 'react-admin'


import * as React from 'react';
import {useState} from 'react';

import {HostedImageInput} from '../components/HostedImageFieldInput';
import {courseOption, edxOption, getIterationName} from "./utils";
import {JSONInput} from "../components/JsonFieldInput";
import {DateInput, DateTimeInput} from '../components/date-time-pickers/';
import ForumReferenceInput from "./iteration_buttons/forum_button/ForumReferenceInput";
import DiscordReferenceInput from "./iteration_buttons/discord_button/DiscordReferenceInput";
import {SanitizedGrid} from '../components/SanitizedElements';
import ToolbarWithoutDelete from '../components/ToolbarWithoutDelete';
import { getApplicationSpecName } from '../application_specs/utils';
import ProgramSelectionDialog from './ProgramSelectionDialog';



const IterationTitle = ({ record }) => {
    return <span>A editar {getIterationName(record, true, false)}</span>;
};

const IterationShiftInput = () => (
    <SanitizedGrid container spacing={1} style={{width: "100%"}}>
        <SanitizedGrid item xs={1}>
            <TextInput source="shift" validate={validateShift} />
        </SanitizedGrid>
        <SanitizedGrid item>
            <FormDataConsumer>
                {({ formData, ...rest }) => (
                    <TextInput source="shift_schedule" {...rest} disabled={!formData.shift} />
                )}
            </FormDataConsumer>
        </SanitizedGrid>
    </SanitizedGrid>
)

const validateIterationName = [required(), regex(/^20\d{2}-\d{2}( .+)?$/, 'Tem de estar no formato YYYY-## str?')];
const validateAsoIterationName = [required(), regex(/^20\d{2}-\d{2}$/, 'Tem de estar no formato YYYY-##')];
const validateShift = [number(), minValue(1)];

const titleValueSchema = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            title: {
                type: 'string'
            },
            value: {
                type: 'string'
            },
        },
        required: ["title", "value"],
        additionalProperties: false,
    },
};

const applicationQuestionsSchema = {
    type: 'array',
    items: {
        type: 'object',
        properties: {
            title: {
                type: 'string'
            },
            description: {
                type: 'string'
            },
            fields: {
                type: 'array',
                items: {
                    type: 'object',
                    properties: {
                        id: {
                            type: 'string'
                        },
                        label: {
                            type: 'string'
                        },
                        description: {
                            type: 'string'
                        },
                        type: {
                            type: 'string',
                            "enum" : ["text", "number", "email", "phone", "area", "date", "select",
                                "select_other", "select_other_number", "tags", "check", "radio",
                                "file", "image"]
                        },
                        multiple: {
                            type: 'boolean'
                        },
                        required: {
                            type: 'boolean'
                        },
                        max: {
                            type: 'number'
                        },
                        options: {
                            type: 'array',
                            items: {
                                type: 'string'
                            }
                        }
                    },
                    additionalProperties: false,
                    required: ["id", "label", "type"],
                }
            }
        },
        required: ["title", "fields"],
        additionalProperties: false,
    },
};

const editionForm = (edit, programSlug) => {
    const isAso = programSlug === "aso";

    return (<TabbedForm toolbar={<ToolbarWithoutDelete />}>
        <FormTab label="Geral">
            {edit && <TextInput fullWidth disabled source="id" />}
            <ReferenceInput perPage={1000} 
                fullWidth source="course_id" 
                reference="courses" 
                filter={{"program/slug": programSlug}}
            >
                <SelectInput optionText={courseOption} validate={required()} />
            </ReferenceInput>
            {!isAso && 
                <ReferenceInput 
                    perPage={1000} 
                    fullWidth 
                    source="application_spec_id" 
                    reference="application_specs" 
                    filter={{"program/slug": programSlug}}
                >
                    <SelectInput optionText={getApplicationSpecName} validate={required()} />
                </ReferenceInput>
            }
            <TextInput 
                fullWidth 
                source="iteration_name" 
                validate={isAso ? validateAsoIterationName : validateIterationName} 
                helperText={isAso ? 
                    "No formato YYYY-##. Exemplo: '2021-03'" : 
                    "No formato YYYY-## str?. Exemplo: '2021-03' ou '2021-03 1º Semestre'"
                } 
            />
            {isAso && <TextInput fullWidth source="old_id" />}
            {!isAso && <IterationShiftInput />}
            <HostedImageInput source="image_id" accept="image/*" validate={required()}/>
        </FormTab>
        <FormTab label="Calendário">
            <DateInput fullWidth source="start_date" validate={required()} helperText="Data em que abre o LMS e não a data da primeira sessão síncrona."/>
            <DateInput fullWidth source="end_date" validate={required()}/>
            {isAso && <DateTimeInput fullWidth source="applications_start_date" />}
            {isAso && <DateTimeInput fullWidth source="applications_end_date" />}
        </FormTab>
        <FormTab label="Detalhes">
            <TextInput fullWidth source="scholarity" validate={required()} />
            <TextInput fullWidth source="location" validate={required()} helperText={"Deve ser 'online' ou corresponder a um dos valores do ficheiro .json de configuração do site das candidaturas. Valores comuns: 'ist' (para Instituto Superior Técnico) e 'champalimaud' (para Centro Champalimaud)."}/>
            <FormDataConsumer fullWidth>
                {({ formData, ...rest }) => formData.location !== "online" &&
                    <TextInput fullWidth source="location_text" {...rest} helperText={"Descrição mais específica da localização, que será enviada por email. Exemplo: 'na Sala 4.35 do Departamento de Matemática do Instituto Superior Técnico'"} />
                }
            </FormDataConsumer>
            <TextInput multiline fullWidth source="short_description"/>
            <ArrayInput source="description" validate={required()}
                        helperText="Este campo (descrição) aceita markdown. Ver mais: https://commonmark.org/help/">
                <SimpleFormIterator>
                    <TextInput multiline fullWidth source=""/>
                </SimpleFormIterator>
            </ArrayInput>
            <ArrayInput source="topics">
                <SimpleFormIterator>
                    <TextInput fullWidth source=""/>
                </SimpleFormIterator>
            </ArrayInput>
            <TextInput multiline fullWidth source="additional_info" />
            <JSONInput source="side_info" schema={titleValueSchema}/>
            <JSONInput source="faq" />
        </FormTab>
        {isAso && 
            <FormTab label="Formato Candidatura">
                <JSONInput source="application_questions" schema={applicationQuestionsSchema} />
            </FormTab>
        }
        {
            edit &&
            <FormTab label="Plataformas">
                <ForumReferenceInput
                    source="forum_category_id"
                    reference="forums"
                    allowEmpty
                    perPage={10000}
                />
                <DiscordReferenceInput
                    source="discord_server_id"
                    reference="discords"
                    allowEmpty
                    perPage={10000}
                />
                <ReferenceInput perPage={1000} source="edx_id" reference="edx_courses" allowEmpty>
                    <SelectInput optionText={edxOption}/>
                </ReferenceInput>
                <BooleanInput fullWidth source="work_before_first" />
            </FormTab>
        }
    </TabbedForm>
    );
};


export const IterationEdit = props => {
    const controllerProps = useEditController(props);
    const { record } = controllerProps;
    const programSlug = record?.course?.program.slug;

    return (
        <Edit {...props} title={<IterationTitle/>}>
            {editionForm(true, programSlug)}
        </Edit>
    );
}

export const IterationCreate = props => {
    const redirect = useRedirect();
    const controllerProps = useCreateController(props);
    const { record } = controllerProps; // record exists only when cloned
    const [programSlug, setProgramSlug] = useState(record?.course?.program.slug);

    return programSlug ? (
            <Create {...props}>
                {editionForm(false, programSlug)}
            </Create>
        ) : (
            <ProgramSelectionDialog
                open={true}
                onClose={() => redirect('/iterations')}
                onSelect={selected => setProgramSlug(selected)}
            />
        );
};
