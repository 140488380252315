import * as React from 'react';
import PropTypes from 'prop-types';
import ThumbDown from '@material-ui/icons/ThumbDown';
import { useEffect, useMemo, useState } from 'react';

import {
    Button,
    useUpdateMany,
    useNotify,
    useRefresh,
    useUnselectAll,
    CRUD_UPDATE_MANY,
    useListContext
} from 'react-admin';

const noSelection = [];

const BulkRejectButton = ({
    selectedIds = noSelection,
}) => {
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('application_submissions');
    const [invalidSelection, setInvalidSelection] = useState(false);
    const {data: listData} = useListContext();

    const selectedSubmissions = useMemo(
        () => selectedIds.map(id => listData[id]).filter(value => value !== undefined),
        [listData, selectedIds]
    );

    useEffect(() => {
        setInvalidSelection(selectedSubmissions.some(submission => submission.assigned_iterations.length > 0));
    }, [selectedSubmissions]);

    const [reject, { loading }] = useUpdateMany(
        'application_submissions',
        selectedIds,
        { 'rejected': true },
        {
            action: CRUD_UPDATE_MANY,
            undoable: true,
            onSuccess: () => {
                notify(
                    'resources.application_submissions.reject_success',
                    'info',
                    {},
                    true
                );
                refresh();
                unselectAll();
            },
            onFailure: () => {
                notify(
                    'resources.application_submissions.reject_error',
                    'warning'
                );
            },
        }
    );

    return (
        <Button
            label="resources.application_submissions.reject_button"
            onClick={reject}
            disabled={loading || invalidSelection}
        >
            <ThumbDown />
        </Button>
    );
};

BulkRejectButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkRejectButton;
