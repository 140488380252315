import * as React from 'react';
import { useState, useEffect, useMemo, useCallback } from 'react';
import { Button, useRefresh, useUnselectAll, useListContext } from 'react-admin';
import PropTypes from 'prop-types';
import AssignIterationsIcon from '@material-ui/icons/AddCircle';
import IterationSelectionInput from './IterationSelectionInput';

const BulkAssignIterationsButton = ({selectedIds}) => {
    const refresh = useRefresh();
    const unselectAll = useUnselectAll('application_submissions');
    const [invalidSelection, setInvalidSelection] = useState(false);
    const [isSelecting, setIsSelecting] = useState(false);
    const {data: listData} = useListContext();

    const selectedSubmissions = useMemo(
        () => selectedIds.map(id => listData[id]).filter(value => value !== undefined),
        [listData, selectedIds]
    );

    useEffect(() => {
        // pre-check: all same application specification
        setInvalidSelection(selectedSubmissions.some(
            sub => sub.application_spec_id !== selectedSubmissions[0].application_spec_id)
        )
    }, [selectedSubmissions]);

    const handleFinish = useCallback((committed) => {
        if (committed) {
            refresh();
            unselectAll("application_submissions");
        }
        else {
            setIsSelecting(false);
        }
    }, [refresh, unselectAll]);

    return isSelecting ?
        <IterationSelectionInput 
            applicationSubmissions={selectedSubmissions}
            onFinish={handleFinish}
            buttonVariant="contained"
        />
    :
        <Button
            label="resources.application_submissions.assign_iterations_button"
            disabled={invalidSelection}
            onClick={() => setIsSelecting(true)}
        >
            <AssignIterationsIcon />
        </Button>
};

BulkAssignIterationsButton.propTypes = {
    selectedIds: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default BulkAssignIterationsButton;
