import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import * as React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {Button, useRecordContext} from 'react-admin';

export const HostedFileField = ({ source }) => {
    const {
        getAccessTokenSilently
    } = useAuth0();

    const record = useRecordContext();

    if (!record || !record[source])
        return null;

    const onClick = async () => {
        const url = process.env.REACT_APP_API_URL + "files/download/" + record[source];
        const token = await getAccessTokenSilently();

        const result = await fetch(url, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        const blob = await result.blob();
        const href = window.URL.createObjectURL(blob);

        // "dirty" way but couldn't find a better one
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = href;
        link.setAttribute('type', 'hidden');
        link.setAttribute('target', '_blank');
        link.click();
    }

    return (
        <Button
            label="Abrir ficheiro"
            onClick={onClick}
        >
            <CloudDownloadIcon />
        </Button>
    );
}

HostedFileField.defaultProps = { addLabel: true };