import {ReferenceInput, SelectInput} from "react-admin";
import * as React from "react";


const IterationFilter = ({source, ...props}) => {
    return (
        <ReferenceInput
            perPage={1000}
            source={source}
            reference="iterations"
            sort={{ field: 'start_date', order: 'DESC' }}
            {...props}
        >
            <SelectInput
                optionText={(choice) =>
                    choice.id // the empty choice is { id: '' }
                        ? `${choice.course.name} ${choice.iteration_name}`
                        : ''
                }
            />
        </ReferenceInput>
    );
};

IterationFilter.defaultProps = {
    label: "Iteração"
}

export default IterationFilter;