import { useState, useEffect, useRef } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import { ButtonGroup, Button, Typography, Box, LinearProgress } from '@material-ui/core';
import { Select, MenuItem } from '@material-ui/core';
import ConfirmIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import {getIterationName} from '../iterations/utils';

const EDIT_STATE = Object.freeze({
    LOADING: 0,
    SELECTING: 1,
    CONFIRMING: 2,
});

const IterationSelectionInput = ({ applicationSubmissions, onFinish, buttonVariant }) => {
    const [editState, setEditState] = useState(EDIT_STATE.LOADING);
    const [allowedIterations, setAllowedIterations] = useState([]);
    const selectedIterationId = useRef();
    const dataProvider = useDataProvider();
    const notify = useNotify();

    const createCourseApplication = (applicationSubmission, iterationId) => {
        const excludedFields = [
            'id', 
            'application_spec', 
            'user', 'rejected', 
            'rejection_email_sent', 
            'assigned_iterations', 
            'created_at', 'updated_at'
        ];
        const applicationSubmissionData = {
            course_iter_id: iterationId,
            accepted: true,
            ...Object.keys(applicationSubmission)
                .filter(key => !excludedFields.includes(key))
                .reduce((obj, key) => {
                    obj[key] = applicationSubmission[key];
                    return obj;
                }, {})
        };
        return dataProvider.create(`applications/internal`, { data: applicationSubmissionData })
    }

    const createCourseApplications = (applicationSubmissions, iterationId) => 
        Promise.all(applicationSubmissions.map(submission => createCourseApplication(submission, iterationId)));
    
    useEffect(() => {
        const promises = applicationSubmissions.map(submission => {
            const { application_spec } = submission;
            const assignedIterationIds = submission.assigned_iterations.map(iter => iter.id)

            return dataProvider.getList('iterations', {
                pagination: { page: 1, perPage: 10000 }, 
                sort: { field: 'start_date', order: 'DESC' },
                filter: { application_spec_id: application_spec.id }
            }).then(({data}) => 
                 data.filter(iter => !assignedIterationIds.includes(iter.id))
            );
        });

        Promise.all(promises).then((allowedItersArray) =>
            allowedItersArray.reduce((common, current) => {
                return common.filter(iteration => 
                    current.some(currentIteration => currentIteration.id === iteration.id)
                );
            }, allowedItersArray[0] || [])
        ).then((commonIterations) => {
            if(commonIterations.length === 0) {
                notify('resources.application_submissions.no_allowed_iterations');
                onFinish(false);
            }
            else {
                setAllowedIterations(commonIterations);
                setEditState(EDIT_STATE.SELECTING);
            }
        }).catch(error => {
            notify('resources.application_submissions.error_getting_allowed_iterations', 'warning');
            console.error(error);
            onFinish(false);
        });

    }, [dataProvider, notify, applicationSubmissions, onFinish]);


    switch (editState) {
        case EDIT_STATE.SELECTING:
            return (
                <Select fullWidth open={true}
                    onChange={(e) => { 
                        selectedIterationId.current = e.target.value; 
                        setEditState(EDIT_STATE.CONFIRMING) }
                    }
                    // material-ui bug: onClose fires before onChange, so we do this 
                    // to cover the case there the user closes by clicking outside
                    onClose={(e) => selectedIterationId.current = null}
                    onClick={(e) => !selectedIterationId.current && onFinish(false)}
                >
                {allowedIterations.map(iteration => (
                    <MenuItem key={iteration.id} value={iteration.id}>
                        {getIterationName(iteration, true, false)}
                    </MenuItem> 
                    ))}
                </Select>
            );
        case EDIT_STATE.CONFIRMING:
            return (
                <ButtonGroup size="small" variant={buttonVariant} style={{maxWidth: '100%'}} >
                    <Button color="primary" onClick={() => {
                        setEditState(EDIT_STATE.LOADING);
                        createCourseApplications(applicationSubmissions, selectedIterationId.current).then(() => {
                            notify("resources.application_submissions.assign_iteration_success");
                            onFinish(true);
                        }).catch(error => {
                            notify('resources.application_submissions.assign_iteration_error', 'warning');
                            console.error(error);
                            onFinish(false);
                        });
                    }}>
                        <Typography noWrap={true} variant="caption">
                            {getIterationName(allowedIterations.find(iter => iter.id === selectedIterationId.current), true, false)}
                        </Typography>
                        <Box mx={0.5} component="span" />
                        <ConfirmIcon />
                    </Button>
                    <Button onClick={() => {
                        selectedIterationId.current = null;
                        onFinish(false);
                    }}>
                        <CancelIcon />
                    </Button>
                </ButtonGroup>
            );
        default:
            return <LinearProgress />;
    }
};

IterationSelectionInput.propTypes = {
    applicationSubmissions: PropTypes.array.isRequired,
    onFinish: PropTypes.func,
    buttonVariant: PropTypes.string,
};

IterationSelectionInput.defaultProps = {
    onFinish: () => {},
    buttonVariant: "outlined",
};

export default IterationSelectionInput;